<template>
    <div class="page_wrapper">
        <div class="page_labels mb-7">
            <h1 class="mb-2">
                {{ $t('auth.restore_by_phone') }}
            </h1>
            <p>
                <router-link :to="{name: 'login'}" class="blue_color">{{ $t('auth.login_portal') }}</router-link>
            </p>
        </div>
               
        <a-form-model
            ref="authForm"
            :model="form"
            layout="vertical"
            autocomplete="off"
            :rules="rules">
            <a-form-model-item
                ref="phone"
                prop="phone">
                <a-input
                    v-model="form.phone"
                    ref="loginEmail"
                    type="tel"
                    :placeholder="$t('auth.your_phone')"
                    autocomplete="username"
                    size="large"
                    @pressEnter="captchaCheck()" />
            </a-form-model-item>
            <div class="flex items-center justify-between">
                <a-button
                    type="primary"
                    :loading="loading"
                    class="px-10 form_buttom form_buttom"
                    size="large"
                    block
                    @click="captchaCheck()">
                    {{ $t('auth.send') }}
                </a-button>
            </div>
            <div class="flex items-center justify-between mt-4">
                <span @click="backStep()" class="cursor-pointer link_btn flex items-center">
                    <i class="fi fi-rr-angle-small-left mr-1"></i>
                    {{ $t('auth.back') }}
                </span>
            </div>
        </a-form-model>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
const regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export default {
    props: {
        setForgotStep: {
            type: Function,
            default: () => {}
        },
        setGlobalForm: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapState({
            usersAuth: state => state.user.usersAuth,
            loginProgress: state => state.loginProgress
        }),
        loginEmail() {
            return this.$refs['loginEmail'];
        },
        loginPassword() {
            return this.$refs['loginPassword'];
        },
        authConfig() {
            return this.$store.state.user.authConfig;
        }
    },
    data() {
        let validateMailOrPhone = (rule, value, callback) => {
            const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
            if (value === '') {
                callback(new Error(this.$t('auth.field_required')));
            } else if (!regex.test(value)) {
                callback(new Error(this.$t('auth.invalid_input')));
            } else {
                callback();
            }
        };
        return {
            selected: [],
            loading: false,
            step: 1,
            form: {
                phone: ''
            },
            rules: {
                phone: [
                    { validator: validateMailOrPhone, trigger: 'change' },
                    { required: true, message: this.$t('auth.field_required'), trigger: 'change' },
                    { max: 255, message: this.$t('auth.required_sym', { sym: 255 }), trigger: 'change' },
                ]
            }
        };
    },
    metaInfo() {
        return {
            title: this.$t('auth.password_recovery_title')
        };
    },
    methods: {
        ...mapActions({
            init: 'user/init'
        }),
        backStep() {
            this.$router.push({ name: 'forgotPassword' });
        },
        selectUser(user) {
            this.selected = [user.email];
            this.form.email = user.email;
            this.$nextTick(() => {
                this.loginPassword.focus();
            });
        },
        captchaCheck() {
            if(this.authConfig.reCAPTCHASiteKey) {
                grecaptcha.ready(() => {
                    this.loading = true;
                    grecaptcha.execute(this.authConfig.reCAPTCHASiteKey, {action: 'forgot_pass'}).then((token) => {
                        this.formSubmit(token);
                    });
                });
            } else {
                this.formSubmit();
            }
        },
        formSubmit(captcha = null) {
            this.$refs.authForm.validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true;
                        let login = JSON.parse(JSON.stringify(this.form.phone)).trim();
                        let formData = {
                            phone: ''
                        };
                        if(captcha)
                            formData.captcha = captcha;
                        else
                            formData.captcha = 'captcha';
                        if(!login.includes('+')) {
                            const fLetter = login.charAt(0);
                            if(fLetter !== '8') {
                                formData.phone = `+${login}`;
                            } else {
                                login = login.substring(1);
                                formData.phone = `+7${login}`;
                            }
                        }
                        const { data } = await this.$http.post('users/forgot_password/', formData);
                        if(data?.message === 'ok') {
                            this.setGlobalForm(formData);
                            this.setForgotStep(2);
                        }
                    } catch(error) {
                        if(error.message) {
                            this.$message.error(error.message, 4);
                        }
                        console.log(error, 'formSubmit');
                    } finally {
                        this.loading = false;
                    }
                } else {
                    this.$message.warning(this.$t('auth.fill_required_fields'));
                    this.loading = false
                    return false;
                }
            });
        }
    }
}
</script>